<template>
  <div class="shiyongList content">
    <div class="contentbox">
      <vue-scroll :ops="ops" :style="{ width: '100%' }">
        <TableQuery
          :formList="formList"
          :formInline="formInline"
          :customArrKey="customArrKey"
          :btnList="btnList"
          :property="queryProperty"
          @btnClickFun="btnClickFun"
          @moveQueryFun="queryBackFun"
          @formDataFun="formDataFun"
          @radioChange="radioChange"
        >
        </TableQuery>
        <div class="p-t-10"></div>
        <SuperTable
          ref="mytable"
          :tableData="tableData"
          :loading="tableLoading"
          :property="tableProperty"
          @sortChange="sortChange"
        ></SuperTable>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
import download from "js-file-download";
import axios from "axios";
export default {
  name: "shiyongList",
  components: {
    TableQuery,
    SuperTable
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          name: "type",
          width: "500px",
          showhide: true,
          multiple: false,
          label: "类型",
          type: "radios",
          clearable: false,
          radiochildren: [
            { labelname: "任意时间", value: "any" },
            { labelname: "本周", value: "week" },
            { labelname: "本月", value: "month" },
            { labelname: "最近一个月", value: "recently" }
          ]
        },
        {
          clearable: true,
          datetype: "date",
          endplaceholder: "请选择结束值",
          format1: "yyyy-MM-dd",
          format: "yyyy-MM-dd",
          showhide: true,
          label: "时间",
          valueformat1: "yyyy-MM-dd",
          type: "DatePickerScope",
          startname: "starttime",
          startplaceholder: "请选择起始值",
          characteristic: "26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          valueformat: "yyyy-MM-dd",
          pickeroptionsend:
            "pickerOptionsEnd26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          name: "choosetime",
          width: "450px",
          endname: "endtime",
          disabled: false,
          control: true
        },
        {
          clearable: true,
          name: "videotypecode",
          showhide: true,
          placeholder: "请选择视频类型",
          label: "视频类型",
          type: "treeselect",
          treedata: []
        },
        {
          name: "name",
          label: "视频名称",
          showhide: true,
          clearable: true,
          placeholder: "请输入视频名称",
          type: "text"
        }
      ],
      formInline: {
        type: "month",
        choosetime: ["", ""],
        videotypecode: "",
        name: ""
      },
      customArrKey: ["choosetime"],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        },
        {
          name: "importButton",
          label: "导出",
          type: "importButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "shiyongList",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [
          {
            headeralign: "center",
            prop: "typename",
            showoverflowtooltip: true,
            showhide: true,
            label: "视频类型",
            align: "center"
          },
          {
            headeralign: "center",
            prop: "videoname",
            showoverflowtooltip: true,
            showhide: true,
            label: "视频名称",
            align: "center"
          },
          {
            headeralign: "center",
            prop: "memo",
            showoverflowtooltip: true,
            showhide: true,
            label: "视频简介",
            align: "center"
          },
          {
            headeralign: "center",
            prop: "visitscount",
            showoverflowtooltip: true,
            showhide: true,
            label: "总访问量",
            align: "center",
            sortable: "custom"
          },
          {
            headeralign: "center",
            prop: "userlimitscount",
            showoverflowtooltip: true,
            showhide: true,
            label: "查看用户授权次数",
            align: "center",
            sortable: "custom"
          },
          {
            headeralign: "center",
            prop: "qrcodecount",
            showoverflowtooltip: true,
            showhide: true,
            label: "二维码申请次数",
            align: "center",
            sortable: "custom"
          }
        ],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: true,
        isOrder: true, // 设置表格有序号
        border: true, // 设置表格有边框
        hideOperation: true
      }
    };
  },
  created() {
    this.findByDomainid();
  },
  mounted() {
    this.getVideoUseList();
  },
  methods: {
    radioChange(val, name) {
      // console.log(name, val);
      if (name == "type") {
        if (val == "any") {
          this.formList[1].showhide = true;
        } else {
          this.formList[1].showhide = false;
        }
      }
    },
    changeTreeNew(val) {
      let arr = [];
      if (val.length !== 0) {
        val.map(item => {
          let obj = {};
          obj.id = item.typeCode;
          obj.label = item.text;
          if (item.nodes && item.nodes.length >= 1) {
            obj.children = this.changeTreeNew(item.nodes);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    findByDomainid() {
      this.$api("videoManage.getVideoTypeTree")
        .invoke()
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            let dataList = data.data;
            if (dataList) {
              let treedata = [];
              if (dataList.length) {
                dataList.map((e, i) => {
                  treedata.push({
                    id: this.changeTreeNew(dataList)[i].id,
                    label: this.changeTreeNew(dataList)[i].label,
                    children: this.changeTreeNew(dataList)[i].children
                  });
                });
              } else {
                treedata = [];
              }
              treedata.unshift({
                id: "",
                label: "全部"
              });
              this.formInline.videotypecode = treedata[0].id;
              const len = this.formList.length;
              for (let i = 0; i < len; i++) {
                if (this.formList[i].name === "videotypecode") {
                  this.formList[i].treedata = treedata;
                }
              }
            }
          } else {
          }
        })
        .catch(() => {});
    },
    getVideoUseList(params = {}) {
      let that = this;
      let starttime = "";
      let endtime = "";
      if (this.formInline.type == "week") {
        starttime = this.$moment().startOf("week").format("YYYY-MM-DD");
        endtime = this.$moment().endOf("week").format("YYYY-MM-DD");
      } else if (this.formInline.type == "month") {
        starttime = this.$moment().startOf("month").format("YYYY-MM-DD");
        endtime = this.$moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.formInline.type == "recently") {
        starttime = this.$moment(new Date())
          .subtract("day", 30)
          .format("YYYY-MM-DD");
        endtime = this.$moment().format("YYYY-MM-DD");
      }
      let obj = {
        starttime: starttime,
        endtime: endtime,
        videotypecode: this.formInline.videotypecode,
        name: this.formInline.name,
        ...params
      };
      this.$api("tongjifenxi.getVideoUseList")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            this.tableData.listData = data.data;
          } else {
          }
        })
        .catch(() => {});
    },
    // 表格排序
    sortChange(params) {
      // console.log(params.prop);
      let obj = {
        sort: "",
        order: ""
      };
      if (params.prop == "visitscount") {
        obj.sort = "VisitsCount";
      } else if (params.prop == "userlimitscount") {
        obj.sort = "UserLimitsCount";
      } else if (params.prop == "qrcodecount") {
        obj.sort = "QRCodeCount";
      }
      if (params.order === "descending") {
        obj.order = "desc";
        this.getVideoUseList(obj);
        // 降序
      } else if (params.order === "ascending") {
        // 升序
        obj.order = "asc";
        this.getVideoUseList(obj);
      } else {
        // 默认
        this.getVideoUseList();
      }
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.getVideoUseList();
      } else if (item.name === "importButton") {
        let starttime = "";
        let endtime = "";
        if (this.formInline.type == "week") {
          starttime = this.$moment().startOf("week").format("YYYY-MM-DD");
          endtime = this.$moment().endOf("week").format("YYYY-MM-DD");
        } else if (this.formInline.type == "month") {
          starttime = this.$moment().startOf("month").format("YYYY-MM-DD");
          endtime = this.$moment().endOf("month").format("YYYY-MM-DD");
        } else if (this.formInline.type == "recently") {
          starttime = this.$moment(new Date())
            .subtract("day", 30)
            .format("YYYY-MM-DD");
          endtime = this.$moment().format("YYYY-MM-DD");
        }
        let obj = {
          starttime: starttime,
          endtime: endtime,
          videotypecode: this.formInline.videotypecode,
          name: this.formInline.name,
          export: true
        };
        // 导出
        axios({
          url:
            this.VUE_APP_BASE_API + "/clockVideo/sysSdlVideo/getVideoUseList",
          data: obj,
          method: "post",
          withCredentials: true,
          responseType: "blob"
        }).then(response => {
          let val = response.headers["content-disposition"];
          val = val.slice(val.search("filename=") + 9);
          download(response.data, decodeURI(escape(val)));
        });
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.getVideoUseList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
      this.getVideoUseList();
    }
  }
};
</script>
<style lang="less" scoped></style>
